<template>
    <section class="rma-page">
        <b-container>
            <Breadcrumbs />
            <div class="rma-container">
                <section class="static pt-7 rma-guest-request-form ">
                    <h2>
                        <cmsBlockSimpleTitle
                            class="content"
                            v-if="typeof returnsGuest == 'object'"
                            :identifier="returnsGuest.identifier"
                        />
                    </h2>
                    <cmsBlockSimple
                        class="content"
                        v-if="typeof returnsGuest == 'object'"
                        :identifier="returnsGuest.identifier"
                    />
                    <div class="alert alert-info rma-alert-info mt-4">
                        <div class="icon fs-2">
                            <i class="fa-solid fa-circle-info fa-xl"></i>
                        </div>
                        <div class="message">
                            <p>{{$t('If you have an account with us, you can also create the return request from your account to bypass this validation.')}}</p>
                            <div>
                                <router-link class="btn btn-primary" :to="{path:'/login/'}">{{$t('Click here to login to your account')}}</router-link>
                            </div>
                        </div>
                    </div>
                    
                    <div class="alert-box" v-show="messages && messages.length">
                        <div class="alert" :class="{'alert-danger': message.type === 'danger', 'alert-success': message.type === 'success'}" v-for="(message, index) in messages" :key="index">{{message.message}}</div>
                    </div>
                    
                    <div class="rma-guest-form">
                        <div class="row">
                            <div class="col-sm-6">
                                <b-form-group
                                    class="account-inputs"
                                    :label="$t('Ordernumber')"
                                    label-class="required-value"
                                    label-for="rma-guest-order-id"
                                    >
                                    <b-form-input
                                        id="rma-guest-order-id"
                                        type="text"
                                        aria-describedby="rma-guest-order-id-feedback"
                                        v-model="order_id"
                                        :state="state.order_id"
                                        :aria-required="true"
                                        ></b-form-input>
                                    <b-form-invalid-feedback
                                        id="rma-guest-order-id-feedback"
                                        >{{errors['order_id']}}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </div>
                            <div class="col-sm-6">
                                <b-form-group
                                    class="account-inputs"
                                    :label="$t('Postcode')"
                                    label-class="required-value"
                                    label-for="rma-guest-postcode"
                                    >
                                    <b-form-input
                                        id="rma-guest-postcode"
                                        type="text"
                                        aria-describedby="rma-guest-postcode-feedback"
                                        v-model="postcode"
                                        :state="state.postcode"
                                        :aria-required="true"
                                        ></b-form-input>
                                    <b-form-invalid-feedback
                                        id="rma-guest-postcode-feedback"
                                        >{{errors['postcode']}}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </div>
                        </div>
                        <div class="text-right">
                            <b-button
                                @click="sendRequest()"
                                type="button"
                                class="btn-success"
                                variant="primary"
                                >{{ $t("send") }}</b-button>
                        </div>
                    </div>
                </section>
            </div>
        </b-container>
    </section>
</template>

<script>
    import Breadcrumbs from "@/esf_utrecht_bankxl/core/components/core/Breadcrumbs.vue";
    import config from "@config";
    import { requestGuestReturn } from "@/esf_utrecht_bankxl/store/modules/rma";
    import cmsBlockSimple from "@/esf_utrecht_bankxl/core/components/core/BlockSimple.vue";
    import cmsBlockSimpleTitle from "@/esf_utrecht_bankxl/core/components/core/BlockSimpleTitle.vue";
    
    export default {
        components: {
            Breadcrumbs,
            cmsBlockSimple,
            cmsBlockSimpleTitle
        },
        
        metaInfo() {
            return {
                meta: [
                    {
                        name: "title",
                        content: "Retour Aanvragen",
                    },
                    {
                        name: "keywords",
                        content: "",
                    },
                    {
                        name: "description",
                        content: "Vraag een retour aan voor uw geplaatste bestelling.",
                    },
                ],
                title: "Retour Aanvragen",
                link: [{ rel: "canonical", href: config.app_url + this.$route.fullPath }],
            };
        },

        computed: {
            returnsGuest() {
                return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
                    "returns_guest"
                );
            },
        },
            
        data() {
            return {
                order_id: null,
                postcode: null,
                errors: {},
                state: {
                    order_id: null,
                    postcode: null
                },
                messages: []
            }
        },
        
        mounted() {
            const bcrumb = {
              current: "Retour Aanvragen",
              routes: [],
            };

            this.$store.commit("breadcrumbs/set", bcrumb);
        },
        
        methods: {
            getState(field) {
                console.log(field);
                if (typeof this.errors[field] === 'undefined' && this[field] !== '') {
                    return null;
                } else if (typeof this.errors[field] === 'undefined') {
                    return true;
                }
                return false;
            },
            
            async sendRequest() {
                let hasErrors = false;
                
                this.state.order_id = true;
                this.state.postcode = true;
                
                this.errors = {};
                this.messages = [];
                
                if (this.order_id === null || this.order_id.trim().length < 10) {
                    this.errors.order_id = this.$t('This is an invalid order number');
                    this.state.order_id = false;
                    hasErrors = true;
                }
                
                if (this.postcode === null || this.postcode.trim().length < 1) {
                    this.errors.postcode = this.$t('Postcode is required');
                    this.state.postcode = false;
                    hasErrors = true;
                }
                
                if (hasErrors) {
                    return;
                }
                
                requestGuestReturn(this.order_id, this.postcode)
                    .then((res) => {
                        if (typeof res.errors !== 'undefined') {
                            this.showErrorResponse(res.errors);
                            return;
                        }
                        this.messages.push({
                            message: this.$t('If there is an order with this order number and postcode, an email has been send.'),
                            type: 'success'
                        });
                    })
                    .catch(() => {
                        this.messages.push({
                            message: this.$t('There was an error during processing. Please try again later.'),
                            type: 'success'
                        });
                    });
            },
            
            showErrorResponse(errors) {
                errors.forEach((error) => {
                    if (typeof error.message === 'undefined') {
                        return;
                    }
                    
                    this.messages.push({
                        message: this.$t(error.message),
                        type: 'danger'
                    });
                });
            }
        }
    }

</script>

<style lang="scss"></style>
